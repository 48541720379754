import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import ProductSizes from './ProductSizes';
import SliderGallery from './SliderGallery';
import ProductSlider from './ProductSlider';
import dot from '../assets/images/dot.png';

const ProductMainStyled = styled.div`
  background: var(--backgroundProduct);
  border: 1px solid var(--greyMidLight);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'image image image image title title'
    'image image image image description description'
    'image image image image description description'
    'image image image image description description'
    'image image image image info info';
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'image'
      'info'
      'description';
  }
  .container {
    padding: 2rem;
    @media (max-width: 1023px) {
      padding: 1.5rem;
    }
  }
  div:not(.product-image--container, .product-size--container, .product-colors--container, .carousel__slider, .carousel__slide) {
    border-bottom: 1px solid var(--greyMidLight);
    &:last-of-type {
      border-bottom: none;
    }
  }
  .product-image--container {
    grid-area: image;
    overflow: hidden;
    min-height: 250px;

    .carousel__slider {
      background: var(--white);
    }
    @media (min-width: 1024px) {
      border-right: 1px solid var(--greyMidLight);
      min-height: 710px;
    }
    .product-controls--container {
      background-color: var(--backgroundProduct);
      border-top: 1px solid var(--greyMidLight);
      opacity: 0;
      animation: fadein 1.5s forwards;
    }
  }
  .product-image {
    /* mix-blend-mode: multiply; */
    max-width: 100%;
  }
  .product-title--container {
    grid-area: title;
  }
  .product-size--container {
    border-top: 1px solid var(--greyMidLight);
  }
  .product-information--container {
    padding: 0;
    grid-area: info;
    display: flex;
    flex-direction: column;
    @media (max-width: 1023px) {
      border-top: 1px solid var(--greyMidLight);
    }
  }
  .product-description--container {
    padding: 0 2rem 1rem;
    max-height: 425px;
    overflow-y: auto;
    position: relative;
    grid-area: description;
  }
  .product-sizes {
    display: grid;
    justify-items: center;
  }
  .product-buttons--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    margin-top: auto;
    padding: 3rem 1rem;
    border-top: 1px solid var(--greyMidLight);
    @media (max-width: 1340px) {
      grid-template-columns: 1fr;
      border-bottom: 1px solid var(--greyMidLight) !important;
      gap: 1rem;
      padding: 1.5rem;
      a.button {
        display: block;
        width: 100%;
      }
    }
  }
  .label {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    justify-self: flex-start;
    &.label-size {
      margin-right: 2rem;
    }
  }
  ul.swatches {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 1rem;
    li {
      border-radius: 24px;
      height: 40px;
      width: 40px;
      box-shadow: var(--boxShadow);
      &.active {
        box-shadow: 0 0 0 3px var(--borderColor);
      }
      span {
        border-width: 20px;
        border-style: solid;
        width: 30px;
        display: inline-block;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        &::after {
          content: '';
          background: purple;
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .size-finder-link {
    display: block;
    margin-top: 2rem;
  }
  .carousel__slider-tray {
    gap: 0;
  }
  // [data-inner-type='SanityFile'] .carousel__inner-slide {
  //   margin-right: -1.7rem;
  // }
  video {
    width: 100%;
  }
`;

const serializers = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === 'blockquote' || style === 'quote') {
        return <blockquote>{props.children}</blockquote>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    code: (props) =>
      console.log('code block', props) || (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
  },
  list: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    )),
  listItem: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    )),
  marks: {
    strong: (props) =>
      console.log('strong', props) || <strong>{props.children}</strong>,
    em: (props) => console.log('em', props) || <em>{props.children}</em>,
    code: (props) =>
      console.log('code', props) || <code>{props.children}</code>,
  },
};

export default function ProductCard(props) {
  const [color, setColor] = useState(props.variants[0] || '');
  const seriesToHide = ['discontinued', 'spare-parts', 'multi'];
  return (
    <ProductMainStyled>
      <div className="product-image--container">
        {!color && <Img fluid={props.image} />}
        {color.variantGallery?.length > 0 && (
          <ProductSlider images={color.variantGallery} />
        )}
      </div>
      <div className="product-title--container container">
        <h1 className="h5">{props.title}</h1>
      </div>
      {props.description && (
        <div className="product-description--container container">
          {props.description && (
            <BlockContent
              blocks={props.description}
              projectId="ji3xh6f0"
              dataset="production"
              serializers={serializers}
              imageOptions={{
                fit: 'max',
              }}
            />
          )}
        </div>
      )}
      <div className="product-information--container container">
        {props.variants.length > 0 && (
          <div className="product-colors--container container">
            <div className="label label-size">Colour: {color.title}</div>
            <ul className="swatches">
              {props.variants.map((item) => (
                <li className={item.title == color.title ? 'active' : ''}>
                  <span
                    style={{
                      borderLeftColor: item.colorFirst,
                      borderTopColor: item.colorFirst,
                      borderRightColor: item.colorSecond,
                      borderBottomColor: item.colorSecond,
                    }}
                    onClick={() => setColor(item)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* {!seriesToHide.includes(props.series) && (
          <div className="product-size--container container">
            <div className="label label-size">Size</div>
            <ProductSizes />
            <a
              href="/support/size-chart"
              target="_blank"
              className="size-finder-link"
            >
              Find your size
            </a>
          </div>
        )} */}
        {props.series != 'discontinued' && (
          <>
            <div className="product-buttons--container container">
              <a
                className="button"
                href={color?.href ? color.href : ''}
                target="_blank"
                rel="noreferrer"
              >
                Buy online
              </a>
              <Link className="button secondary" to="/dealers">
                Find a Dealer
              </Link>
            </div>
          </>
        )}
      </div>
    </ProductMainStyled>
  );
}
