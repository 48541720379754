import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';

const BreadcrumbStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
  padding: 0 0 2rem;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
  span {
    text-transform: uppercase;
    color: var(--border);
    border-bottom: 1px solid var(--border);
    padding: 0 0 2rem;
    @media (max-width: 1023px) {
      padding: 0 0 1rem;
    }
  }
  a {
    color: var(--borderColor);
    text-decoration: none;
    cursor: pointer;
  }
`;

export default function ProductBreadcrumb(props) {
  return (
    <BreadcrumbStyled>
      <span className="previous-page">
        <a role="button" onClick={() => navigate(-1)}>
          Previous page
        </a>
      </span>
      <span className="shoe-category">
        Category:{' '}
        <Link to={`/category/${props.category.slug.current}`}>
          {props.category.title}
        </Link>
      </span>
      <span className="shoe-series">
        Series:{' '}
        <Link to={`/series/${props.series.slug.current}`}>
          {props.series.title}
        </Link>
      </span>
    </BreadcrumbStyled>
  );
}
