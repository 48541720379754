import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';

export default function ProductSlider(props) {
  console.dir(props.images);
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      isIntrinsicHeight
      totalSlides={props.images.length}
      infinite
      hasMasterSpinner
      className="product-slider"
    >
      <Slider>
        {props.images.map((image, index) => (
          <Slide index={index} data-inner-type={image.__typename}>
            {image.__typename == 'SanityFile' ? (
              <video autoPlay loop muted>
                <source
                  poster={props.images[1]?.asset.fluid.srcWebp || ''}
                  src={image.asset.url}
                  type={image.asset.mimeType}
                />
              </video>
            ) : (
              <Image src={image.asset.fluid.srcWebp} />
            )}
          </Slide>
        ))}
      </Slider>
      {props.images.length > 1 && (
        <div className="product-controls--container container">
          <div className="carousel-button-group right">
            <ButtonBack>
              <FiChevronLeft />
            </ButtonBack>
            <ButtonNext>
              <FiChevronRight />
            </ButtonNext>
          </div>
        </div>
      )}
    </CarouselProvider>
  );
}
