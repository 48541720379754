import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { VscAdd } from '@react-icons/all-files/vsc/VscAdd';
import { VscRemove } from '@react-icons/all-files/vsc/VscRemove';
import { FaHandsHelping } from '@react-icons/all-files/fa/FaHandsHelping';
import ProductGallery from './ProductGallery';
import SparesGallery from './SparesGallery';

const ProductDrawerStyled = styled.div`
  background: var(--backgroundProduct);
  border: 1px solid var(--borderColor);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
  margin-top: 2rem;
  padding: 3rem 2rem;

  display: flex;
  flex-direction: column;
  h5 {
    cursor: pointer;
  }
  .product-drawer--content {
    width: 100%;
    transition: all calc(var(--speed) * 2);
    transform: translateY(-100%);
    height: 0px;
    visibility: hidden;
    display: none;
  }
  .product-drawer--content.open {
    display: flex;
    flex-direction: column;
    transform: translateY(0%);
    height: auto;
    visibility: visible;
  }
  svg.toggle-icon {
    color: var(--borderColor);
    vertical-align: middle;
    float: right;
  }
  .technical-specifications {
    margin: 3rem 1rem;
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 3rem;
    font-size: 1.5rem;
    .title {
      font-weight: 700;
    }
    .details a {
      display: block;
      margin-top: 1rem;
    }
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }
`;

export default function ProductDrawer(props) {
  const [drawerState, drawerSet] = useState(false);
  function toggleDrawer() {
    drawerSet(!drawerState);
  }
  return (
    <ProductDrawerStyled>
      <h5
        role="button"
        tabIndex={0}
        onClick={toggleDrawer}
        className={drawerState ? 'is-open' : ''}
      >
        {props.title}
        {drawerState == false ? (
          <VscAdd className="toggle-icon" />
        ) : (
          <VscRemove className="toggle-icon" />
        )}
      </h5>
      <div
        className={
          drawerState
            ? 'product-drawer--content open'
            : 'product-drawer--content'
        }
      >
        {props.gallery && <ProductGallery images={props.gallery} />}
        {props.content && (
          <div className="technical-specifications">
            {props.content.map((item) => (
              <>
                <span className="title">{item.title}</span>
                <span className="details">
                  {item.details}{' '}
                  {item.title.includes('BOA') ? (
                    <a
                      href="https://www.boafit.com/en-us/support/warranty"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more about the BOA Guarantee
                    </a>
                  ) : (
                    ''
                  )}
                </span>
              </>
            ))}
          </div>
        )}
        {props.parts && <SparesGallery images={props.parts} />}
      </div>
    </ProductDrawerStyled>
  );
}
